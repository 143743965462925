import Layout from "@core/components/layout"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Post from "../components/post"

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD MMM YYYY")
        title
      }
    }
  }
`

function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const post = {
    title: frontmatter.title,
    date: frontmatter.date,
    content: html,
  }
  return (
    <>
      <Helmet title={`${post.title} - dctcheng`} defer={false} />
      <Layout>
        <Post post={post}></Post>
      </Layout>
    </>
  )
}

export default Template
